
.coontentprodotti{
  display: flex;
  flex-direction: column;
  align-items: center;
  
  margin-bottom: 50px;

}
.prodotti{
  display: flex;
  flex-direction: row;
 justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  margin-bottom: 50px;
}