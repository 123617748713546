.card {
  display: flex;
  flex-direction: column;
  width: 280px;
  height: auto;
  margin: 10px;
  border: 1px solid #d7d7d7;
  border-width: 1px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #ffffff;
}
.titolo {
  font-size: 1.4em;
  margin-bottom: 9px;
  font-weight: bold;
}
.dati_orizzontali{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
}
.image {
  height: 200px;
  width: 98%;
  object-fit: cover;
  margin: 0 auto;
}
.ingredienti{
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 190px;
  margin: 0 5px;
}
.it {
  font-size: 1em;
  font-style: italic;
  margin-top: 4px;
  margin-bottom: 6px;
  display: block;
  height: auto;
  width: 98%;
  margin: 0 auto;
  word-wrap: break-word;
  flex-wrap: wrap;
}
.en {
  font-size: 0.7em;
  font-style: italic;
  margin-top: 4px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  width: 98%;
  margin: 0 auto;
  height: auto;
  word-wrap: break-word;
}
.prezzo {
  display: flex;
  width: auto;
  margin: 3px auto;
  font-size: 1.5em;
  align-items: center;
  justify-content: center;
}

.pulsanti {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.conenitore-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: auto;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(96, 94, 94);
  margin: 1em 0;
  padding: 0;
}
.qty {
  align-items: center;
  justify-content: center;
}

.btn {
  padding: 0.5em 2.1em;
  border-radius: 1rem;
  border: 1px solid #172d69;
  color: #ffffff;
  background-color: #fbdedb;
  display: inline-block;
  background: #39879c;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-family: sans-serif;
  font-weight: 800;
  font-size: 0.85em;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  position: relative;
}
.btn:hover,
.btn:focus {
  opacity: 0.8;
}
.btn:active {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
.btn.block {
  display: block !important;
}
.btn.circular {
  border-radius: 50em !important;
}

/* Colours */

.purple {
  background-color: #172d69;
}